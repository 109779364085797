import Vue from 'vue';
import Vuex from 'vuex';
import i18next from 'i18next';
import App from './App.vue';
import router from './router';
import factory from './api';
import { FormError } from './api';

i18next.init({
  lng: 'en',
  debug: false,
  resources: {
    en: {
      translation: {
        lbl: {
          'project': {
            'search': 'search',
          },
          'controls': {
            'up': 'up',
            'down': 'down',
            'edit': 'edit',
            'details': 'details',
            'more': 'more',
            'wait': 'please wait',
            'success': 'success',
            'unlink': 'unlink',
          },
          'error': {
            'unknown': 'something went wrong',
            'missing': 'page not found',
            'action': {
              'oauth': 'handshake failed',
              'auth': 'no handshake in process',
              'nomerge': 'no merge in process',
              'load': 'unable to load data',
              'upload': 'unable to upload data',
            },
          },
          'title': {
            'changes': 'changes',
            'domains': 'domains',
            'engines': 'engines',
            'portals': 'portals',
            'providers': 'providers',
            'lookups': 'lookups',
            'regexes': 'regular expressions',
            'configuration': 'configuration',
            'data_types': 'data types',
            'prototypes': 'prototypes',
            'events': 'events',
            'subscriptions': 'subscriptions',
            'commands': 'commands',
            'queries': 'queries',
            'aggregate_roots': 'aggregate roots',
            'policy_groups': 'policy groups',
            'options': 'options',
            'properties': 'properties',
            'fields': 'field constraint',
            'named': 'named constraint',
            'indexes': 'indexes',
            'entities': 'entities',
            'tables': 'tables',
            'processes': 'processes',
            'subprocesses': 'subprocesses',
            'interrupts': 'interrupts',
            'user_inputs': 'user inputs',
            'contexts': 'contexts',
            'actions': 'actions',
            'views': 'views',
            'components': 'components',
            'navigations': 'navigations',
            'workflows': 'workflows',
            'dependencies': 'dependencies',
            'renamed': 'renamed',
          },
          'objects': {
            'singleton': {
              'fields': 'field constraint',
              'named': 'named constraint',
              'indexes': 'index',
              'entities': 'entity',
              'tables': 'table',
              'navigations': 'navigation',
              'workflows': 'workflow',
              'dependencies': 'dependency',
            },
            'plural': {
              'fields': 'field constraints',
              'named': 'named constraints',
              'indexes': 'indexes',
              'entities': 'entities',
              'tables': 'tables',
              'navigations': 'navigations',
              'workflows': 'workflows',
              'dependencies': 'dependencies',
            },
            'cache_seconds': 'cache seconds',
            'service': 'service',
            'errors': 'errors',
            'unnamed': 'new {{ label }}',
            'field': 'field',
            'name': 'name',
            'claim': 'claim',
            'subject': 'subject',
            'tenant': 'tenant',
            'provider': 'provider',
            'proxy': 'proxy',
            'proxied': 'proxied',
            'description': 'description',
            'audit': 'audit',
            'requirements': {
              'contexts': 'associations',
              'actions': 'parent',
              'views': 'parent',
              'domains': 'requirements',
              'engines': 'requirements',
              'portals': 'requirements',
              'processes': 'state transitions',
              'policy_groups': 'parent',
            },
            'expression': 'expression',
            'properties': 'properties',
            'options': 'options',
            'attributes': 'attributes',
            'mutable': 'mutable',
            'policy_groups': 'policy group',
            'page_size': 'page_size',
            'whitelist': 'whitelist',
            'escalate': 'escalate',
            'query': 'query',
            'key_type': 'key type',
            'data_type': 'data type',
            'event_type': 'event type',
            'emits': 'emits',
            'source': 'source',
            'role': 'role',
            'explicit': 'explicit',
            'general_access': 'general access',
            'metadata': 'metadata',
            'serial': 'serial',
            'validated': 'validated',
            'sink': 'sink',
            'return_type': 'return type',
            'state_type': 'state type',
            'table': 'table',
            'prefix': 'prefix',
            'view': 'view',
            'domains': 'domain',
            'engines': 'engine',
            'portals': 'portal',
            'providers': 'provider',
            'lookups': 'lookup',
            'regexes': 'regular expression',
            'configuration': 'configuration',
            'data_types': 'data type',
            'prototypes': 'prototype',
            'events': 'event',
            'subscriptions': 'subscription',
            'commands': 'command',
            'queries': 'query',
            'aggregate_roots': 'aggregate root',
            'fields': 'field constraint',
            'named': 'named constraint',
            'indexes': 'index',
            'entities': 'entity',
            'tables': 'table',
            'summary': 'summary',
            'search': 'search',
            'document': 'document',
            'processes': 'process',
            'subprocesses': 'subprocess',
            'interrupts': 'interrupt',
            'user_inputs': 'user input',
            'contexts': 'context',
            'actions': 'action',
            'views': 'view',
            'components': 'component',
            'navigations': 'navigation',
            'workflows': 'workflow',
            'dependencies': 'dependency',
            'target': 'target',
            'renamed': 'renamed',
            'src': 'source',
            'context': 'context',
            'body': 'fields',
          },
          'modal': {
            'ok': 'ok',
            'update': 'update',
            'new': 'create as new',
            'create': 'create',
            'category': 'category',
            'group': 'Set group name',
            'ungroup': 'Ungroup',
            'disconnect': 'disconnect',
            'navigation': 'navigation',
            'back': 'back',
            'parent': 'I want a new...',
            'child': 'I want to...',
            'grandchild': 'I want...',
            'null': 'Unknown',
            'use': 'to use',
            'realm': 'realm',
            'move': 'move to',
            'object': 'object',
            'primitive': 'primitive',
            'domains': 'Domain',
            'engines': 'Engine',
            'portals': 'Portal',
            'providers': 'Provider',
            'source': 'Source',
            'view': 'View',
            'lookups': 'Lookup',
            'configuration': 'Configuration',
            'regexes': 'Regular expression',
            'policy_groups': 'Policy group',
            'data_types': 'Data type',
            'prototypes': 'Prototype',
            'events': 'Event',
            'aggregate_roots': 'Aggregate root',
            'commands': 'Command',
            'queries': 'Query',
            'subscriptions': 'Subscription',
            'processes': 'Process',
            'subprocesses': 'Subprocess',
            'user_inputs': 'User input',
            'interrupts': 'Interrupt',
            'views': 'View',
            'actions': 'Action',
            'contexts': 'Context',
            'components': 'Component',
            'properties': 'Property',
            'entities': 'Entity',
            'indexes': 'Index',
            'dependencies': 'Dependency',
            'navigations': 'Navigation',
            'workflows': 'Workflow',
            'target': 'Target',
            'service': 'Service',
            'key_type': 'Key type',
            'data_type': 'Data type',
            'event_type': 'Event type',
            'emits': 'Emitted event',
            'sink': 'Sink',
            'return_type': 'Return type',
            'state_type': 'State type',
            'property': 'set as...',
            'set': 'update the set key',
            'src': 'the base path',
            'fields': 'assign field to...',
            'table': 'edit the table',
            'audit': 'insert into audit message',
            'summary': {
              'add': 'add to summary projection',
              'remove': 'remove from summary projection',
              'reset': 'reset summary projection',
            },
            'search': {
              'add': 'add to search projection',
              'remove': 'remove from search projection',
            },
            'entity': {
              'add': 'add to entity',
              'remove': 'remove from entity',
            },
            'transition': {
              'add': 'possible next state',
              'remove': 'unreachable state',
            },
            'index': {
              'add': 'add to index',
              'remove': 'remove from index',
              'replace': 'replace {{value}} in index'
            },
            'document': {
              'add': 'add to document storage',
              'remove': 'remove from document storage',
            },
            'disjoint': {
              'add': 'add to disjoint constraint',
              'remove': 'remove from disjoint constraint',
              'create': 'Disjoint constraint',
            },
            'distinct': {
              'update': 'set distinct constraint',
              'create': 'Distinct constraint',
            },
            'check': {
              'add': 'include in check constraint',
              'create': 'Check constraint',
            },
          }
        },
        msg: {
          'lock': {
            'splash': 'engineering the future',
          },
          'error': {
            'auth': 'oauth handshake failed',
            'expired': 'your session has expired - please log in again',
            'unknown': 'An unexpected error occurred. Please try again soon.',
            'uncaptured': 'the form cannot display an important error',
            'missing': 'The page you were looking for doesn\'t exist. We looked everywhere.',
          },
          'project': {
            'search': 'search for an acorn project',
          },
          'nav': {
            'home': 'return to home',
          },
          'objects': {
            'none': 'no objects found',
            'noresults': 'no objects matched',
            'missingattr': 'this {{ label }} has no {{ attr }}',
            "count": "{{count}} {{ singleton }}",
            "count_plural": "{{count}} {{ plural }}",
            "constraints": "{{count}} {{type}} constraint",
            "constraints_plural": "{{count}} {{type}} constraints",
            "configuration": "{{count}} configuration option",
            "configuration_plural": "{{count}} configuration options",
            'audit': 'write a template string if this event is audited',
            'page_size': 'Set page size',
            'cache_seconds': 'Set the cache duration in seconds',
            'create': 'create a new {{type}}',
            "affected": "{{ count }} objects affected",
            "affected_plural": "{{ count }} objects affected",
          },
          'layer': {
            "created": "{{count}} new object created",
            "created_plural": "{{count}} new objects created",
            "updated": "{{count}} object merged",
            "updated_plural": "{{count}} objects merged",
            "deleted": "{{count}} object deleted",
            "deleted_plural": "{{count}} objects deleted",
          },
          'modal': {
            'domains': 'Domains are your data storage engines. They control how information is stored, manipulated, and exposed to internal services. Each domain is self-contained, and cannot access any other service directly.',
            'engines': 'Engines co-ordinate users and system processes. They store local state containing in-flight work, chain together domain commands, and await user input when required.',
            'portals': 'Portals are stateless system boundaries. They describe the application programming interface (API) used by websites and devices, access the internal APIs for engines and portals (including emitted events), and produce a coherent interaction for client systems.',
            'providers': 'Providers enable access control management for collections of resources.',
            'move': 'Moving an object will update all other objects that reference it.',
            'policy_groups': 'Policy groups are a logical collection of resources for access control purposes.',
            'lookups': 'Lookups are a textual data type with a limited set of acceptable values. These values are determined at runtime by the platform administrator. Examples include country or currency codes.',
            'regexes': 'Regular expressions (regexes) are a textual data type with a prescribed pattern. This pattern follows the Python regex specification.',
            'source': 'The source of a flow is the trigger that begins the process. This can be an event, or accessing data through a view, context, or aggregate root.',
            'data_types': 'Data types describe a group of data points. They are used to describe the shape of inputs, outputs, and units of storage, as well as fields in other data types.',
            'prototypes': 'Prototypes are a group of constraints applied to a data type. They help avoid duplicating constraints applied to other objects, where constraints range from making individual fields optional to full manual programmatic validation.',
            'events': 'Events are a special kind of data type that are published, and then subscribed listeners can recieve notifications. They enable reactivity, and allow extension without modification.',
            'aggregate_roots': 'Aggregate roots are the fundamental unit of storage in a domain. They define a data type as an entity, then provide standard storage and access APIs for these entities.',
            'commands': 'Commands are data modification actions. Their inputs contain all the required information to perform the action, and an event is publish on successful completion.',
            'queries': 'Queries are a view over information in a domain. They reshape or collate information to improve performance or reduce duplication of effort on the part of data users.',
            'subscriptions': 'Subscriptions are asynchronous processing of an event. The results of a subscription are published as an event on completion.',
            'processes': 'Processes are state machines that track an asynchronous process. They allow for compensation if any steps in the process fail to complete normally, as well as co-ordinate background processing with user input requests.',
            'subprocesses': 'Subprocesses describe a chain of asynchronous processing. This chain starts with a command, follows potentially many subscriptions, and terminates with an event. The enclosing process will be notified on the success or failure of this chain.',
            'user_inputs': 'User inputs fulfil the request for further input created by an interrupt. Each interrupt can have many valid types of user inputs.',
            'interrupts': 'Interrupts are requests for further input before the process can continue. The enclosing process will emit an event and stall on the interrupt until data is recieved, or it is notified of a timeout.',
            'views': 'Views are an endpoint that can only return data. Either that data is scrollable, or is a single value. They are stateless, and cannot perform any data mutation, but the result may provide enough information to navigate to other endpoints.',
            'actions': 'Actions are the data modification endpoint. They may contain an implicit view, and/or be stateful, and the result of an action can return enough information to navigate to a related context.',
            'contexts': 'Contexts provide structure to a portal. They are a special form of view that can only return a single object based on the context parameters, but may be associated with other contexts and contain views and actions. Associated contexts will have a superset of the context parameters, and contained views/actions will recieve all context parameters in addition to their locally defined parameters.',
            'components': 'Components are a single UI widget assocated with a context. They enable embeddable user interactions that would be too complex/tedious to be handled by the default portal.',
            'audit': 'The field can be included in the audit message template',
            'summary': {
              'add': 'The field can be added to the search projection',
              'remove': 'The field is currently included in the search projection',
              'reset': 'The search projection can be reset to include only this field',
            },
            'transition': {
              'add': 'The process cannot currently reach the state when starting from this entity. Adding the state transition makes it reachable.',
              'remove': 'The process can reach the state when starting from the current entity. Removing the state transition makes it unreachable.',
            },
            'entity': {
              'add': 'The field can be added to the entity',
              'remove': 'The field is currently included in the entity',
            },
            'index': {
              'add': 'The field can be added to the index',
              'remove': 'The field is currently included in the index',
            },
            'search': {
              'add': 'The field can be searchable',
              'remove': 'The field is currently included in the search projection',
            },
            'document': {
              'add': 'The field can be added to the document',
              'remove': 'The field is currently included in the document',
            },
            'disjoint': {
              'add': 'The field can be targeted by the disjoint constraint',
              'remove': 'The field is currently included in the disjoint constraint',
              'create': 'Disjoint constraints ensure multiple fields cannot conflict',
            },
            'distinct': {
              'update': 'The field can be set as the target of the distinct constraint',
              'create': 'Distinct constraints ensure a field does not contain duplicates',
            },
            'check': {
              'add': 'The field can be added to the check constraint',
              'create': 'Check constraints evaluate an expression over one or more fields',
            },
            'set': 'The field can be used as the set key',
            'properties': 'Properties are individual data fields',
            'options': 'Options are primitive configuration values',
            'dependencies': 'Dependencies are an explicit requirement for an underlying service',
            'navigations': 'Navigations are links between the returned data and the inputs to another service',
            'workflows': 'Workflows are used to document usage patterns for a portal.',
            'target': 'Proxied services target an underlying service',
            'data_type': 'Data types are links to external property definitions',
            'sink': 'Sinks accept output from other services',
            'event_type': 'Events allow other services to react to changes',
            'return_type': 'Return types describe the information returned by a service',
            'state_type': 'State types describe the locally-stored information for a service',
            'view': 'The view specifies the primary discovery mechanism for entities in this context',
            'group': 'Enter a name to be used as the title of the group',
          }
        },
        cmd: {
          'lock': {
            'auth': 'authorize app',
          },
          'project': {
            'search': 'enter query...',
          },
          'objects': {
            'name': 'enter the unique name of the {{ label }}',
            'claim': 'enter the 3 letter claim code',
            'subject': 'enter a subject key',
            'tenant': 'enter a tenant key',
            'field': 'enter the path to the field',
            'query': 'enter a query name',
            'role': 'enter the role name',
            'description': 'write a short description of the {{ label }} contents',
            'audit': 'write a template string if this event is audited',
            'expression': 'enter the regular expression',
            'provider': '-- select --',
            'fields': 'enter the field name',
            'table': 'enter the table name',
            'prefix': 'enter the column prefix',
          },
          'error': {
            'action': {
              'oauth': 'return to page',
              'auth': 'return to page',
              'nomerge': 'return to home',
              'load': 'retry',
              'upload': 'return to model',
            },
          },
        },
        help: {
          'lock': {
            'auth': 'acorn uses a mediawiki instance to store your designs, and needs permission to edit this wiki on your behalf. For security reasons, the app never directly handles your account credentials',
          },
          'project': {
            'search': 'projects on the acorn wiki are searchable by their name, description, or URL fragment. More advanced search can be found directly on the wiki',
          },
          'objects': {
            'name': 'Enter a short, unique, and memorable name. The name must start with a lowercase letter ("a" to "z"), and can contain lowercase letters, numbers ("0" to "9"), with an underscore ("_") between words.',
            'description': 'Enter a detailed summary of the contents of this project. This summary helps with quickly scanning a list of projects to find a relevant match.',
            'claim': 'Enter a unique 3 letter claim code. This claim can contain only lowercase letters. It is shorthand for the database and authorization service, and so should not be changed without reason.',
            'subject': 'Enter a subject key, used to store the external identity for users, if relevant',
            'tenant': 'Enter a tenant key, used to store the external identity of organizations, if the provider is multi-tenanted',
            'provider': 'Select the claim provider. Claim providers specify the tenanting status, and map from policy groups to resource permissions.',
            'domains': 'Enter a brief summary of the intended contents of the domain. This summary helps with understanding what data can be found in the domain, and why the decision to group this data together has been made.',
            'engines': 'Enter a brief summary of the intended contents of the engine. This summary helps with understanding what processes are managed by this engine, and why the decision to group these processes together has been made.',
            'portals': 'Enter a brief summary of the intended usage of this portal. This summary helps with understanding the intended clients of the API, as well as when clients should choose to use this portal over any potential other options.',
            'providers': 'Enter a brief summary of the provider. This summary helps designers understand when this provider should be used.',
            'data_types': 'Enter a brief summary of the concept described by this data type. This summary helps with understanding why the included information is related.',
            'prototypes': 'Enter a brief summary of the constraints applied by this prototype. This summary helps with understanding how and when to use this prototype.',
            'events': 'Enter a brief summary of the contents of this event, as well as when it is fired. This summary helps with understanding when to subscribe to this event.',
            'commands': 'Enter a brief summary of the side-effects of this command. This summary helps with understanding the implications of using this command.',
            'queries': 'Enter a brief summary of the information collated by this query. This summary helps with understanding when to prioritize this query over the default data APIs.',
            'aggregate_roots': 'Enter a brief summary of the concept tracked by this aggregate root. This summary helps with understanding the difference between the more abstract data type and this concrete instance.',
            'processes': 'Enter a brief summary of the general flow of this process. This summary helps with understanding the thread tying together each of the state transitions.',
            'subprocesses': 'Enter a brief summary of the event chain tracked by this subprocess. This summary helps with understanding the failure modes of the asynchronous processing.',
            'interrupts': 'Enter a brief summary of why the process must pause at this interrupt. This summary helps with understanding the type of input that is required for processing to continue.',
            'user_inputs': 'Enter a brief summary of the information requested from the user. This summary helps with understanding the category of user that should be permitted to fulfil this request.',
            'entities': 'Enter a brief summary of the significance of this object lifecycle. This summary helps with understanding the usage patterns supported by this entity.',
            'indexes': 'Enter a brief summary of the reason for this index existing. This summary helps with understanding the usage patterns supported by this index.',
            'views': 'Enter a brief summary of the data returned by this view. This summary helps with understanding the usecase of this view',
            'actions': 'Enter a brief summary of the net effect of this action, and where it will affect data in the system. This summary helps with understanding the implications of performing this action',
            'properties': 'Add or edit data fields',
            'options': 'Add or edit configuration items',
            'contexts': 'Enter a brief summary of the data returned by this context. This summary helps with understanding the both the use of this context and the relationship with the actions and views that belong here.',
            'components': 'Enter a brief summary of the user interaction streamlined by this component. This summary helps with understanding when this component should be used.',
            'check': 'Enter a brief summary of the invariant enforced by this check constraint. This message will be presented to users when data violates the constraint',
            'distinct': 'Enter a brief summary of the invariant enforced by this distinct constraint. This message will be presented to users when data violates the constraint',
            'policy_groups': 'Enter a brief summary of the intended audience of this policy group.',
            'disjoint': 'Enter a brief summary of the invariant enforced by this disjoint constraint. This message will be presented to users when data violates the constraint',
            'configuration': 'Enter a brief summary of the options included in this configuration. This summary helps administrators understand how to use these options',
            'audit': 'Enter a template string if this event is audited',
            'validated': 'Validated objects require custom code to be written to apply more complex constraints',
            'requirements': {
              'contexts': 'Associated contexts have a strict subset of properties relative to this context. This guarantees the local information is enough to navigate to these associated contexts.',
              'actions': 'A parent context provides all of its properties to the action',
              'views': 'A parent context provides all of its properties to the view.',
              'domains': 'Requirements are either hard or soft dependencies on other realms. A hard dependency involves data access/modification, whereas a soft dependency involves the use of concepts (such as data type) defined within the realm',
              'engines': 'Requirements are either hard or soft dependencies on other realms. A hard dependency involves data access/modification, whereas a soft dependency involves the use of concepts (such as data type) defined within the realm',
              'portals': 'Requirements are either hard or soft dependencies on other realms. A hard dependency involves data access/modification, whereas a soft dependency involves the use of concepts (such as data type) defined within the realm',
              'processes': 'State transitions allow a process to delegate work to either the system (a subprocess) or the users (a user input), then await the result.',
              'policy_groups': 'A child policy group can only contain resources that belong to the parent.',
            },
            'workflows': 'Workflows are used to document usage patterns for a portal.',
            'regexes': 'Enter a short description of the intended information captured by data that matches the regular expression.',
            'view': 'Choose the primary discovery view for this context.',
            'lookups': 'Enter a short description of the relationship between the values in the lookup',
            'expression': 'Enter the regular expression following the python regular expression syntax.',
            'proxied': 'Proxied objects are direct passthrough implementations from underlying engine or domain object. They provide a simple mechanism for exposing internal functionality publicly.',
            'mutable': 'Data modifications methods are exposed to portals and engines. Useful for user interface related data',
            'page_size': 'The results are returned as a collection with cursor-based pagination',
            'cache_seconds': 'Enter the external cache duration in seconds for the returned data',
            'whitelist': 'Unsecured use of this endpoint is permitted',
            'service': 'The context is implemented as a service',
            'general_access': 'All users will have access to the policy group resources',
            'serial': 'Each command must be executed serially',
            'role': 'Enter the externally managed name for the policy group',
            'escalate': 'Full portal permissions will be adopted after the endpoint is successfully invoked',
            'query': 'The action may be pre-populated by data elsewhere in the system',
            'key_type': 'Select the primary key primitive type',
            'data_type': {
              'aggregate_roots': 'Select the data type of the entity for this aggregate root',
              'prototypes': 'Select the data type validated by this prototype',
            },
            'source': {
              'policy_groups': 'Select the parent policy group. A policy group can only contain resources that exist in the direct parent.',
            },
            'event_type': {
              'commands': 'Select the event type emitted by this command',
              'subscriptions': 'Select the event type emitted by this subscription',
              'processes': 'Select the event type emitted by this process',
              'subprocesses': 'Select the event type awaited by this subprocess',
              'interrupts': 'Select the event type emitted by this interrupt',
            },
            'sink': {
              'subprocesses': 'Select the command invoked by this subprocess',
              'actions': 'Select the context returned as the result of this action',
            },
            'return_type': {
              'queries': 'Select the return type for this query',
              'views': 'Select the return type for this view',
              'contexts': 'Select the return type for this context',
            },
            'state_type': 'Select the data type of the internal state of this process',
            'field': 'Enter the field being configured',
            'table': 'Enter the custom table name, if overriding the defaults',
            'prefix': 'Enter the prefix applied to all columns within this table',
          },
        },
      },
    },
  },
});

Vue.config.productionTip = false;
Vue.use(Vuex);
Vue.mixin({
  methods: {
    $label(key, args) {
      return i18next.t(`lbl.${key}`, args);
    },
    $message(key, args) {
      return i18next.t(`msg.${key}`, args);
    },
    $command(key, args) {
      return i18next.t(`cmd.${key}`, args);
    },
    $help(key, args) {
      return i18next.t(`help.${key}`, args);
    },
    $error(status, error) {
      const fields = new Map();
      error.errors.forEach((child) => {
        var src = child.source || '/';
        if (fields.has(src)) {
          fields.get(src).push(child);
        } else {
          fields.set(src, [child]);
        }
      });
      switch (error.code) {
        case 'permissions.2000':
        case 'permissions.2100':
        case 'permissions.2200':
        case 'permissions.2300':
          return new FormError(
            'your credentials have expired - please log in again',
            status,
            error,
            fields,
          );
        case 'permissions.2500':
          return new FormError(
            'this action is currently disabled - please try again later',
            status,
            error,
            fields,
          );
        default:
          return new FormError(
            error.detail,
            status,
            error,
            fields,
          );
      }
    },
  },
});

new Vue({
  router,
  store: new Vuex.Store({
    modules: {
      api: factory(),
    },
  }),
  render: h => h(App),
}).$mount('#app');
