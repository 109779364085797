<template>
  <div>
    <article v-if="lockscreen"
        class="uk-height-viewport uk-flex uk-flex-column uk-flex-middle">
      <div class="uk-flex-1"></div>
      <section class="uk-width-large uk-padding uk-flex uk-flex-column uk-flex-middle">
        <SiteHeader></SiteHeader>
        <form
          class="uk-width-expand uk-flex uk-flex-right uk-flex-column uk-form-stacked uk-text-left"
          v-on:submit.prevent="submit()">
          <p class="uk-margin-left uk-margin-right uk-text-center">
            {{ $message('lock.splash') }}
          </p>

          <footer class="uk-flex uk-flex-center uk-flex-middle uk-margin-small">
            <template v-if="error || toast">
              <span class="uk-text-muted uk-text-right uk-margin-small-right uk-margin-small-left uk-flex-auto">
                {{ error || toast }}
              </span>
              <i class="uk-icon uk-margin-small-right uk-flex-none" uk-icon="icon: warning"></i>
            </template>
            <div uk-spinner v-if="active" class="uk-margin-small-right uk-flex-none"></div>
            <button
                class="uk-button uk-button-primary uk-border-rounded uk-flex-none"
                v-bind:disabled="active">
              {{ $command('lock.auth') }}
              
            </button>
          </footer>
            <p class="uk-margin-bottom uk-margin-remove-top uk-text-center uk-text-meta uk-animation-fade" v-if="help">
              {{ $help('lock.auth') }}
            </p>
        </form>
      </section>
      <div class="uk-flex-1"></div>
      <SiteFooter class="uk-flex-none uk-flex-bottom"></SiteFooter>
    </article>
    <slot v-else></slot>
    <aside class="uk-overlay uk-position-top-right uk-padding-small" style="opacity: 0.8;">
      <ul class="uk-iconnav uk-iconnav-vertical uk-padding-none">
        <li>
          <button class="uk-icon-button uk-button-primary"
              :class="{ 'uk-button-secondary': help }"
              uk-icon="question" v-on:click="toggleHelp()"
              v-if="!helpless">
          </button>
        </li>
        <li>
          <button class="uk-icon-button uk-button-primary"
              uk-icon="lock" v-on:click="lockWiki()" v-if="wiki && unlocked == wiki">
          </button>
        </li>
        <slot name="menu" v-if="!lockscreen"></slot>
      </ul>
    </aside>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SiteHeader from '@/components/SiteHeader.vue';
import SiteFooter from '@/components/SiteFooter.vue';

export default {
  name: 'Lock',
  props: {
    wiki: String,
    helpless: Boolean,
  },
  components: {
    SiteHeader,
    SiteFooter,
  },
  data() {
    return {
      active: false,
      username: null,
      password: null,
      error: null,
    };
  },
  mounted() {
    if (this.wiki) {
      this.$store.commit('api/CHECK_LOCK', this.wiki)
    }

    if (!this.wiki || this.wiki == this.unlocked) {
      this.$emit('ready');
    }
  },
  methods: {
    lockWiki() {
      this.lock(this.wiki);
      this.$router.push('/home');
    },
    submit() {
      this.active = true;
      this.error = null;
      this.handshake(this.wiki).then(
        ({ url }) => {
          window.location.replace(url);
        },
      ).catch((err) => {
        this.active = false;
        this.error = err.message ?? 'An unexpected error occurred';
      });
    },
    ...mapActions('api', ['handshake', 'lock', 'toggleHelp']),
  },
  computed: {
    lockscreen() {
      return this.wiki && (this.active || this.unlocked != this.wiki );
    },
    ...mapState('api', ['toast', 'unlocked', 'help']),
  },
};
</script>