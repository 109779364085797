import { HttpHandler } from './sdk/meta.js';
import ConsumerService from './sdk/consumer.js';

class OAuthHandler extends HttpHandler {

    #wiki;
    #auth;
    #refresh;
    #expiry;
    #pending;

    constructor(wiki, auth, refresh, expiry) {
      super();
      this.#wiki = wiki;
      this.#auth = auth;
      this.#refresh = refresh;
      this.#expiry = expiry;
    }

    async sendAsync(request, signal = null) {
      let auth = this.#auth;
      let now = new Date();
      if (this.#expiry < now) {
        if (!this.#pending) {
          try {
            this.#pending = this.doRefresh(now, signal);
            auth = await this.#pending;
          } finally {
            this.#pending = null;
          }
        } else {
          auth = await this.#pending;
        }
      }

      request.headers.append('Authorization', auth);
      return await fetch(request, { signal });
    }

    async doRefresh(now, signal) {
      let response = await fetch(
        `/auth/oauth/${this.#wiki}/refresh`,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ refresh_token: this.#refresh }),
          signal,
        });

      if (response.ok) {
        let { refresh_token, access_token, expires_in, token_type } = await response.json();
        this.#refresh = refresh_token;
        this.#auth = `${token_type} ${access_token}`;
        this.#expiry = new Date(now.getTime() + 1000 * expires_in);

        if (typeof localStorage !== 'undefined' && this.#wiki in localStorage) {
          try {
            let existing = JSON.parse(localStorage.getItem(this.#wiki));
            localStorage.setItem(this.#wiki, JSON.stringify({
              ...existing,
              refresh_token,
              access_token,
              token_type,
              expiry: this.#expiry,
            }));
          } catch {
            localStorage.removeItem(this.#wiki);
          }
        }
        return this.#auth;
      } else {
        let error = await response.json();

        const fields = new Map();
        if (error) {
          error.errors.forEach((child) => {
            var src = child.source || '/';
            if (fields.has(src)) {
              fields.get(src).push(child);
            } else {
              fields.set(src, [child]);
            }
          });
        }
        throw new FormError(
          error.detail,
          response.status,
          error,
          fields,
        );
      }
    }
}

export function equal(src, dst) {
  const tx = typeof src, ty = typeof dst;
  if (src && dst && tx === 'object' && tx === ty) {
    return Object.keys(src).length === Object.keys(dst).length
      && Object.keys(src).every(key => equal(src[key], dst[key]));
  } else {
    return src === dst;
  }
}

/**
 * Helper class to track error state
 */
export class FormError extends Error {
  /**
   * Create a new form error
   * @param {*} msg the human readable message
   * @param {*} status the HTTP status code
   * @param {*} error the raw API error object
   * @param {*} fields all specific field errors
   */
  constructor(msg, status, error, fields) {
    super(msg);
    this.msg = msg;
    this.status = status || 500;
    this.error = error || {};
    this.fields = !fields ? new Map() : fields;
  }

  /**
   * Claim a field error, removing it from the pending errors
   * @param {String} selector
   */
  claim(selector) {
    if (this.fields.has(selector)) {
      const value = this.fields.get(selector);
      this.fields.delete(selector);
      if (value.length < 2) {
        return value[0];
      }
      return [
        ...value.slice(0, -1),
        `and ${value[value.length - 1]}`,
      ].join(', ');
    }
    return undefined;
  }

  /**
   * Check for a root cause
   */
  isCausedBy(code) {
    return this.error.errors && this.error.errors.length && this.error.errors[0].code === code;
  }

  /**
   * Claim the remaining errors
   */
  unclaimed() {
    const general = this.fields.get('/');
    this.fields.delete('/');
    if (this.assert_claimed()) {
      const msg = 'the form cannot display an important error - please contact support';
      return general ? [
        msg,
        ...general,
      ] : [msg];
    }
    return general || [];
  }

  /**
   * Ensure some mechanism captures unexpected errors
   */
  assert_claimed() {
    this.fields.forEach((value, key) => {
      console.error({
        msg: `Field "${key}" has an unclaimed error`,
        error: value,
      });
    });
    return this.fields.size;
  }
}
/**
 * Helper function for converting API errors to form errors
 */
export function asFormError(context, status, error) {
  const fields = new Map();
  if (error) {
    error.errors.forEach((child) => {
      var src = child.source || '/';
      if (fields.has(src)) {
        fields.get(src).push(child);
      } else {
        fields.set(src, [child]);
      }
    });
  }
  switch (error.code) {
    case 'permissions.2000':
    case 'permissions.2100':
    case 'permissions.2200':
    case 'permissions.2300':
      context.commit('LOCK');
      return new FormError(
        'your credentials have expired - please log in again',
        status,
        error,
        fields,
      );
    case 'permissions.2500':
      return new FormError(
        'this action is currently disabled - please try again later',
        status,
        error,
        fields,
      );
    default:
      return new FormError(
        error.detail,
        status,
        error,
        fields,
      );
  }
}

function processItems(items) {
  var local = [];
  var remote = [];
  var attributes = [];
  var inline = [];
  var amap = new Map();
  var smap = new Map();
  items.local.forEach(({ group, name, value, matched, inlined }) => {
    if (!smap.has(group)) {
      local.push({ name: group, label: group.replace('_', ' '), options: [] });
      smap.set(group, local[local.length - 1]);
    }
    if (!amap.has(group)) {
      attributes.push({
        name: group,
        label: group.replace('_', ' '),
        isArray: !name,
        options: [],
        local: new Map(),
        remote: new Map(),
        offset: 0,
        injected: false,
      });
      amap.set(group, attributes[attributes.length - 1]);
    }
    var item = smap.get(group);
    var attribute = amap.get(group);
    var { child, option } = createAttribute(name, value, matched, group, inlined);
    if (!matched) {
      attribute.local.set(child.name, child);
    }
    attribute.options.push(child);
    item.options.push(option);
    if (inlined) {
      inline.push(option, child);
    }
  });
  var dmap = new Map();
  items.remote.forEach(({ group, name, value, matched, inlined }) => {
    if (!dmap.has(group)) {
      remote.push({ name: group, label: group.replace('_', ' '), options: [] });
      dmap.set(group, remote[remote.length - 1]);
    }
    if (!amap.has(group)) {
      attributes.push({
        name: group,
        label: group.replace('_', ' '),
        isArray: !name,
        options: [],
        local: new Map(),
        remote: new Map(),
        offset: 0,
        injected: false,
      });
      amap.set(group, attributes[attributes.length - 1]);
    }
    var item = dmap.get(group);
    var attribute = amap.get(group);
    var { child, option } = createAttribute(name, value, matched, group, inlined);
    child.group = 1;
    if (matched) {
      attribute.offset++;
    } else if (attribute.injected) {
      attribute.remote.set(child.name, child);
      attribute.options.splice(attribute.offset, 0, child);
      attribute.offset++;
    }
    else {
      attribute.remote.set(child.name, child);
      if (attribute.isArray) {
        for (var i = attribute.offset; i < attribute.options.length; i++) {
          var obj = attribute.options[i];
          if (!obj.matched) {
            attribute.offset++;
          }
          else {
            child.locked = false;
            break;
          }
        }
        attribute.options.splice(attribute.offset, 0, child);
        attribute.offset++;
      }
      else {
        attribute.options.push(child);
      }
    }
    item.options.push(option);
    if (inlined) {
      inline.push(option, child);
    }
  });
  return { local, remote, attributes, amap, inline };
}

function createAttribute(name, value, matched, group, inlined) {
  var option = {
    name: name || value.name,
    value,
    label: value,
    metadata: null,
    selected: matched,
    disabled: matched,
    inlined,
  };
  var child = {
    group: 0,
    value,
    name: option.name,
    label: option.label,
    metadata: option.metadata,
    locked: true,
    matched,
    inlined,
    renamed: null,
  };
  if (!name) {
    child.discarded = !matched;
  }

  switch (group) {
    case 'metadata':
      option.label = option.name;
      child.label = option.name;
      break;
    case 'properties':
      option.label = value.name;
      option.metadata = value.description;
      child.label = value.name;
      child.metadata = value.data_type;
      break;
    case 'options':
      option.label = value.name;
      option.metadata = value.example ? `[${value.example}] ${value.description}` : value.description;
      child.label = value.name;
      child.metadata = value.example ? `[${value.example}] ${value.data_type}` : value.data_type;
      break;
    case 'proxy':
      option.label = value.target;
      option.metadata = value.renamed.map(obj => `${obj.name} -> ${obj.value}`).join(', ');
      child.label = value.target;
      child.metadata = option.metadata;
      break
    case 'fields':
    case 'named':
      option.label = value.name;
      option.metadata = `[${value.value}] ${value.description}`;
      child.label = value.name;
      child.metadata = value.value;
      break;
    case 'dependencies':
      option.label = value.service;
      option.metadata = value.permissions;
      child.label = value.service;
      child.metadata = value.permissions;
      break;
    case 'navigations':
      option.label = value.dst;
      option.metadata = `[${value.src || 'top-level'}${value.explicit ? ', explicit' : ''}] ${value.mappings.map(mappingLabel).join(', ')}`,
      child.label = value.dst;
      child.metadata = option.metadata;
      break;

  }
  return { child, option };
}

function mappingLabel(obj) {
  if (obj.context) {
    return `${obj.src} -> ${obj.dst} (context)`;
  }

  return `${obj.src} -> ${obj.dst}`;
}

/**
 * Base factory for the VueX communication module
 */
export default () => {
  const minDelay = 10;

  function wrapAction(context, promise, mutation, delay) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        promise.then((result) => {
          if (mutation) {
            context.commit(mutation);
          }
          resolve(result);
        }).catch((err) => {
          if (err instanceof FormError) {
            reject(err);
          } else if (!context.state.unlocked) {
            console.error(err);
            reject(new FormError('an unexpected error occurred'));
          } else {
            console.error(err);
            reject(new FormError('something went wrong - please contact support'));
          }
        });
      }, delay || minDelay);
    });
  }

  function extractBody(context, response) {
    switch (response.status) {
      case 204:
        return Promise.resolve({});
      case 503:
        return Promise.reject(
          new FormError('this action is temporarily disabled - please try again soon', 503),
        );
      case 404:
        return Promise.reject(
          new FormError('resource not found', 404),
        );
      case 500:
        var contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((body) => {
            throw asFormError(context, response.status, body);
          });
        }
        return Promise.reject(new FormError('an unexpected error occurred', 500));
      default:
        return response.ok ? response.json() : response.json().then((body) => {
          throw asFormError(context, response.status, body);
        });
    }
  }

  return {
    namespaced: true,
    state: {
      help: false,
      toast: null,
      unlocked: null,
      service: null,
      session: null,
      merge: null,
      stage: null,
      cached: [],
    },
    actions: {
      index(context) {
        if (typeof localStorage === 'undefined') {
          return Promise.reject(
            asFormError(context, 401, {
              code: 'permissions.2000',
              title: 'No access to local storage',
              detail: 'Acorn requires your local storage to store access tokens',
              errors: [],
            }),
          );
        } else if ('acorn' in localStorage) {
          try {
            let value = JSON.parse(localStorage.getItem('acorn'));
            let tokens = value.tokens ?? [];
            tokens.sort((a, b) => (a.name<b.name?-1:(a.name>b.name?1:0)));
            return Promise.resolve(tokens);
          } catch {
            localStorage.removeItem('acorn');
          }
        }

        return Promise.resolve([]);
      },
      toggleHelp(context) {
        context.commit('HELP');
      },
      handshake(context, wiki) {
        return wrapAction(context, fetch(
          `/auth/oauth/${wiki}/initiate`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              callback_uri: `${window.location.protocol}//${window.location.host}/oauth/${wiki}?state=${encodeURIComponent(window.location.pathname + window.location.search)}`,
            }),
          }).then(response => extractBody(context, response)));
      },
      async unlock(context, { wiki, verifier, token }) {
        let now = new Date();
        let { refresh_token, access_token, expires_in, token_type } = await fetch(
          `/auth/oauth/${wiki}/complete`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ verifier, token }),
          }).then(response => extractBody(context, response));

        let expiry = new Date(now.getTime() + 1000 * expires_in);
        let handler = new OAuthHandler(
          wiki,
          `${token_type} ${access_token}`,
          refresh_token,
          expiry);

        let service = await ConsumerService.GetEntryPointAsync(handler, '/acorn/consumer/');
        if (service.hasSession) {
          let resource = await service.asSessionUri().getAsync();
          let session = service.from(resource);
          context.commit('UNLOCK', { wiki, service, session });

          if (typeof localStorage !== 'undefined') {
            localStorage.setItem(wiki, JSON.stringify({
              refresh_token,
              access_token,
              token_type,
              expiry,
              service: service.save(),
              session: session.save(),
            }));

            if ('acorn' in localStorage) {
              try {
                let value = JSON.parse(localStorage.getItem('acorn'));
                value.tokens = value.tokens.filter(x => x.name !== wiki);
                value.tokens.push(resource);
                localStorage.setItem('acorn', JSON.stringify(value));
              } catch {
                localStorage.setItem('acorn', JSON.stringify({tokens: [ resource ]}));
              }
            } else {
                localStorage.setItem('acorn', JSON.stringify({tokens: [ resource ]}));
            }
          }
        } else {
          throw asFormError(context, 401, {
            code: 'permissions.2000',
            title: 'Please log in',
            detail: 'The service requires elevated permissions',
            errors: [],
          });
        }
      },
      lock(context) {
        context.commit('LOCK');
      },
      prepareMerge(context, { name, params, project, mapper }) {
        context.commit('START_MERGE', { name, params, project, mapper });
      },
    },
    /* eslint-disable no-param-reassign */
    mutations: {
      HELP(state) {
        state.help = !state.help;
      },
      CHECK_LOCK(state, wiki) {
        if (!wiki) {
          state.unlocked = null;
          state.service = null;
          state.session = null;
        } else if (wiki != state.unlocked) {
          if (typeof localStorage !== 'undefined' && wiki in localStorage) {
            try {
              let value = JSON.parse(localStorage.getItem(wiki));
              let handler = new OAuthHandler(
                wiki,
                `${value.token_type} ${value.access_token}`,
                value.refresh_token,
                new Date(value.expiry));
              let service = new ConsumerService(handler, '/acorn/consumer/', value.service);
              if (service) {
                state.unlocked = wiki;
                state.service = service;
                state.session = new ConsumerService(handler, '/acorn/consumer/', value.session);
              } else {
                localStorage.removeItem(wiki);
              }
            } catch {
              localStorage.removeItem(wiki);
            }
          }
        }
      },
      UNLOCK(state, { wiki, service, session }) {
        state.unlocked = wiki;
        state.service = service;
        state.session = session;
      },
      LOCK(state) {
        if (typeof localStorage !== 'undefined' && state.unlocked && state.unlocked in localStorage) {
          localStorage.removeItem(state.unlocked);
          if ('acorn' in localStorage) {
            try {
              let value = JSON.parse(localStorage.getItem('acorn'));
              value.tokens = value.tokens.filter(x => x.name !== state.unlocked);
              localStorage.setItem('acorn', JSON.stringify(value));
            } catch {
              localStorage.removeItem('acorn');
            }
          }
        }
        state.unlocked = null;
        state.service = null;
        state.session = null;
      },
      START_MERGE(state, { name, params, project, mapper }) {
        state.merge = { name, params, project, mapper };
      },
      MERGE_CATEGORY(state, { parent, category, title, objects }) {
        state.stage = {
          title,
          mode: 'category',
          parent,
          category,
          objects: objects.map(({ local, remote, discard }) => ({
            version: 0,
            category,
            discard,
            local,
            remote,
          })),
        }
      },
      MERGE_CATEGORY_UPDATE(state, { idx, field, value }) {
        var obj = state.stage.objects[idx];
        obj.local[field] = value;
        obj.version++;
      },
      MERGE_CATEGORY_ADD(state, idx) {
        var obj = state.stage.objects[idx];
        if (obj.local && obj.remote) {
          state.stage.objects.push({
            version: 0,
            category: obj.category,
            remote: obj.remote,
            local: null,
          });
          obj.remote = null;
        }
        obj.discard = false;
        obj.version++;
      },
      MERGE_CATEGORY_DISCARD(state, idx) {
        var obj = state.stage.objects[idx];
        if (obj.local && obj.remote) {
          state.stage.objects.push({
            version: 0,
            category: obj.category,
            remote: obj.remote,
            local: null,
          });
          obj.remote = null;
        }
        obj.discard = true;
        obj.version++;
      },
      MERGE_CATEGORY_MERGE(state, { local, remote }) {
        var obj = state.stage.objects[local];
        for (var i = state.stage.objects.length; i--;) {
          var target = state.stage.objects[i];
          if (target.remote.name === remote) {
            obj.remote = state.stage.objects[i].remote;
            state.stage.objects.splice(i, 1);
            break;
          }
        }
        obj.discard = false;
        obj.version++;
      },
      MERGE_CATEGORY_LOCAL(state) {
        state.stage.objects.forEach(obj => {
          if (obj.local && obj.remote) {
            state.stage.objects.push({
              version: 0,
              category: obj.category,
              discard: true,
              remote: obj.remote,
              local: null,
            });
            obj.remote = null;
          }
          obj.discard = !!obj.remote;
          obj.version++;
        });
      },
      MERGE_CATEGORY_REMOTE(state) {
        state.stage.objects.forEach(obj => {
          if (obj.local && obj.remote) {
            state.stage.objects.push({
              version: 0,
              category: obj.category,
              discard: false,
              remote: obj.remote,
              local: null,
            });
            obj.remote = null;
          }
          obj.discard = !!obj.local;
          obj.version++;
        });
      },
      MERGE_LAYER(state, {
        title,
        src,
        mapper,
        created,
        updated,
        deleted,
        origin,
        positions,
        dependencies,
      }) {
        state.stage = {
          title,
          mode: 'layer',
          src,
          mapper,
          created,
          updated,
          deleted,
          origin,
          positions,
          dependencies,
        };
      },
      MERGE_LAYER_MOVE(state, { id, x, y }) {
        var obj = state.stage.mapper.objects.get(id);
        obj.position = { x, y };
        state.stage.positions.set(id, { x, y });
      },
      MERGE_LAYER_LINK(state, { src, dst, exists }) {
        var obj = state.stage.mapper.objects.get(dst);
        if (exists) {
          obj.dependencies.push(src);
        } else {
          obj.dependencies = obj.dependencies.filter(id => id !== src);
        }

        if (exists && state.stage.dependencies.has(dst)) {
          state.stage.dependencies.get(dst).add(src);
        } else if (!exists && state.stage.dependencies.has(dst)) {
          state.stage.dependencies.get(dst).delete(src);
        } else {
          state.stage.dependencies.set(dst, new Set(obj.dependencies));
        }

      },
      MERGE_LAYER_LOCAL(state) {
        state.stage.positions.forEach((v, k) => {
          var obj = state.stage.mapper.objects.get(k);
          obj.position = { ...v };
        });

        state.stage.dependencies.forEach((v, k) => {
          var obj = state.stage.mapper.objects.get(k);
          obj.dependencies = [...v];
        });
      },
      MERGE_LAYER_REMOTE(state) {
        state.stage.origin.forEach((v, k) => {
          var obj = state.stage.mapper.objects.get(k);
          obj.dependencies = [...v.dependencies];
          obj.position = { ...v.position };
        });
      },
      MERGE_OBJECT(state, { title, name, description, category, parent, src, dst, items }) {
        var { local, remote, attributes, amap, inline } = processItems(items);
        state.stage = {
          name,
          description,
          title,
          mode: 'object',
          parent,
          category,
          local,
          remote,
          attributes,
          amap,
          inline,
          src,
          dst,
          version: 0,
        };
      },
      MERGE_OBJECT_SELECT(state, { group, name, value, selected }) {
        var parent = group ? state.stage.remote : state.stage.local;
        var attribute = parent.find(obj => obj.name === name);
        var option = attribute.options.find(obj => obj.name === value);
        option.selected = selected;

        var attr = state.stage.amap.get(name);
        var target = group ? attr.remote : attr.local;
        var obj = target.get(value);
        obj.discarded = !selected;
        obj.renamed = null;

        if (selected) {
          var affected = group ? attr.local : attr.remote;
          var opposite = group ? state.stage.local : state.stage.remote;
          if (affected.has(value)) {
            var discard = affected.get(value);
            discard.discarded = true;
            if (!group) {
              discard.renamed = obj.name;
              obj.renamed = discard.name;
            } else {
              discard.renamed = null;
            }
            var container = opposite.find(obj => obj.name === name);
            container.options.find(obj => obj.name === value).selected = false;
          }

          if (value === 'proxy') {
            state.stage.inline.forEach(obj => {
              if ('selected' in obj) {
                obj.selected = false;
              } else {
                obj.discarded = true;
              }
            })
          } else if (option.inlined) {
            if (affected.has('proxy')) {
              affected.get('proxy').discarded = true;
              var proxy = opposite.find(obj => obj.name === 'proxy');
              proxy.options.find(obj => obj.name === 'proxy').selected = false;
            }
          }
        }
        state.stage.version++;
      },
      MERGE_OBJECT_SHIFT(state, { name, index }) {
        var attr = state.stage.amap.get(name);
        var obj = attr.options[index];
        attr.options[index - 1].locked = false;
        var sibling = attr.options[index + 1];
        if (sibling) {
          sibling.locked = !sibling.locked;
        }

        attr.options.splice(index, 1);
        attr.options.splice(index - 1, 0, obj);

        sibling = attr.options[index - 2];
        if (sibling && sibling.group === obj.group) {
          obj.locked = true;
        }
        state.stage.version++;
      },
      MERGE_OBJECT_MATCH(state, { name, index, value }) {
        var attr = state.stage.amap.get(name);
        var obj = attr.options[index];
        if (obj.renamed) {
          let target = attr.options.find(x => x.group !== obj.group && x.name == obj.renamed);
          target.renamed = null;
        }

        if (value) {
          let target = attr.options.find(x => x.group !== obj.group && x.name == value);
          target.discarded = true;
          target.renamed = obj.name;
          obj.renamed = target.name;
        } else {
          obj.renamed = null;
        }
        state.stage.version++;
      },
      MERGE_OBJECT_LOCAL(state) {
        state.stage.local.forEach(obj => {
          obj.options.forEach(option => {
            option.selected = true;
            option.discarded = false;
          });
        });
        state.stage.remote.forEach(obj => {
          obj.options.forEach(option => {
            option.selected = option.disabled;
            option.discarded = option.disabled;
          });
        });
        state.stage.attributes.forEach(obj => {
          var attr = state.stage.amap.get(obj.name);
          obj.options.forEach(option => {
            option.discarded = option.group !== 0;
            var target = option.group ? attr.local : attr.remote;
            option.renamed = target.has(option.name) ? option.name : null;
          });
        });
        state.stage.version++;
      },
      MERGE_OBJECT_REMOTE(state) {
        state.stage.local.forEach(obj => {
          obj.options.forEach(option => {
            option.selected = option.disabled;
            option.discarded = option.disabled;
          });
        });
        state.stage.remote.forEach(obj => {
          obj.options.forEach(option => {
            option.selected = true;
            option.discarded = false;
          });
        });
        state.stage.attributes.forEach(obj => {
          obj.options.forEach(option => {
            option.discarded = option.group !== 1 && !option.matched;
            option.renamed = null;
          });
        });
        state.stage.version++;
      },
      MERGE_ATTRIBUTES(state, { title, name, description, parent, src, dst, items }) {
        var { local, remote, attributes, amap, inline } = processItems(items);
        state.stage = {
          name,
          description,
          title,
          mode: 'attributes',
          parent,
          local,
          remote,
          attributes,
          amap,
          inline,
          src,
          dst,
          version: 0,
        };
      },
      COMPLETE_MERGE(state, failure) {
        if (failure) {
          var obj = state.cached.find(obj => equal(obj.params, state.merge.params))

          if (obj) {
            obj.cached = failure.mapper;
            obj.errors = failure.errors;
          } else {
            state.cached.push({
              params: state.merge.params,
              errors: failure.errors,
              cached: failure.mapper,
            })
          }
        }
        state.merge = null;
      },
      POP_CACHED(state, obj) {
        var idx = state.cached.indexOf(obj);
        state.cached.splice(idx, 1);
      }
    },
  };
};