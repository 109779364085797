<template>
  <main id="app">
    <router-view/>
  </main>
</template>

<script>
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

UIkit.use(Icons);

export default {
  name: 'App',
};
</script>

<style lang="less">
@import "./assets/less/theme.less";

.uk-light .uk-description-list > dt {
  color: @global-inverse-color;
}

.uk-light .uk-placeholder {
  border-color: @global-inverse-color;
}

.uk-subnav {
  margin-left: -40px !important;
  padding-left: 0 !important;
}
// #app {
//   font-family: 'Avenir', Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }
// #nav {
//   padding: 30px;
//   a {
//     font-weight: bold;
//     color: #2c3e50;
//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>