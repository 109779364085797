<template>
  <Lock v-on:ready="load()">
    <article class="uk-height-viewport uk-flex uk-flex-column uk-flex-middle uk-flex-center">
      <div class="uk-flex-1"></div>

      <section class="uk-width-expand uk-margin uk-width-xlarge@s uk-flex-none uk-padding">
        <SiteHeader class="uk-margin-top"></SiteHeader>
        <section>

          <form class="uk-flex uk-flex-column uk-form-stacked uk-text-left"
              v-on:submit.prevent="submit()">
            <label class="uk-flex uk-flex-column uk-margin-small-bottom">
              <span>wiki</span>
              <div class="uk-form-controls uk-inline">
                  <i class="uk-form-icon" uk-icon="icon: folder"></i>
                  <input
                    class="uk-input"
                    type="text"
                    v-model="wiki"
                    placeholder="enter your wiki name"
                    autofocus
                    required
                    :readonly="active"
                    spellcheck="false"
                    autocorrect="off"
                    autocapitalize="off"
                    autocomplete="off">
              </div>
              <p class="uk-margin-large-left uk-margin-small-top uk-text-right uk-text-meta
                    uk-margin-remove-bottom uk-animation-fade" v-if="help">
                This app edits designs on your wiki. Enter the wiki name to authenticate the application.
              </p>
            </label>
            <SubmitFooter :active="active" :error="error" label="open">
            </SubmitFooter>
          </form>
        </section>
        <section>
          <ul class="uk-list uk-margin-remove-top">
              <li  v-for="item in wikis" :key="item.name">
                  <article class="uk-comment uk-margin-small-left">
                      <header class="uk-comment-header uk-grid-medium uk-flex-middle uk-margin-small-bottom" uk-grid>
                          <div class="uk-width-expand uk-flex uk-flex-middle">
                              <a :href="item.url" class="uk-margin-small-right">
                                <i uk-icon="folder"></i>
                              </a>
                              <h4 class="uk-comment-title uk-margin-remove">
                                <router-link :to="{ name: 'projects', params: { wiki: item.name }}">
                                  {{ item.name }}
                                </router-link>
                              </h4>
                          </div>
                      </header>
                      <div class="uk-comment-body">
                      <p>{{ item.description }}</p>
                      </div>
                  </article>
              </li>
          </ul>
        </section>
      </section>

      <div class="uk-flex-1"></div>

      <SiteFooter class="uk-flex-none uk-flex-bottom"></SiteFooter>
    </article>
  </Lock>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import Lock from '@/components/Lock.vue';
import SiteHeader from '@/components/SiteHeader.vue';
import SiteFooter from '@/components/SiteFooter.vue';
import SubmitFooter from '@/components/SubmitFooter.vue';

export default {
  name: 'Home',
  components: {
    Lock,
    SiteHeader,
    SiteFooter,
    SubmitFooter,
  },
  data() {
    return {
      error: null,
      active: false,
      wikis: [],
      wiki: null,
    };
  },
  methods: {
    submit() {
      this.active = true;
      this.error = null;
      this.handshake(this.wiki).then(
        ({ url }) => {
          window.location.replace(url);
        },
      ).catch((err) => {
        this.active = false;
        this.error = err.message ?? 'An unexpected error occurred';
      });
    },
    load() {
      this.active = true;
      this.index().then(wikis => this.wikis = wikis)
      .catch((err) => {
        this.error = err.message ?? 'An unexpected error occurred';
      })
      .finally(() => {
          this.active = false;
      });
    },
    ...mapActions('api', ['index', 'handshake']),
  },
  computed: {
    ...mapState('api', ['help']),
  },
}
</script>