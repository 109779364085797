<template>
  <footer class="uk-flex uk-flex-right uk-flex-row-reverse uk-flex-middle">
    <button
        class="uk-button uk-button-primary uk-border-rounded uk-flex-none"
        :class="{'uk-button-danger': danger}"
        v-bind:disabled="active || invalid">
      {{ label }}
    </button>
    <div uk-spinner v-if="active" class="uk-margin-small-right uk-flex-none"></div>
    <template v-if="error">
      <span class="uk-text-muted uk-text-right uk-margin-small-right uk-margin-small-left">
        {{ error }}
      </span>
      <i class="uk-icon uk-flex-none" uk-icon="icon: warning"></i>
    </template>
    <span class="uk-flex-auto"></span>
    <div class="uk-flex-none" v-if="cancel">
      <button
          class="uk-button uk-button-text uk-border-rounded uk-modal-close"
          v-bind:disabled="active">
        {{ cancel }}
      </button>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'SubmitFooter',
  props: {
      cancel: String,
      label: String,
      error: String,
      active: Boolean,
      invalid: Boolean,
      danger: Boolean,
  },
}
</script>