// This Source Code Form is subject to the terms of the Mozilla Public
// License, v. 2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at https://mozilla.org/MPL/2.0/.

/**
 * A client HTTP handler, responsible for authentication and retries.
 */
export class HttpHandler {

    /**
     * Submit an API request asynchronously
     *
     * This method should be overridden to decorate the request with authentication.
     *
     * @param {Request} request - a request object
     * @param {AbortSignal} [signal] - an abort signal
     *
     * @returns {Promise<Response>}
     */
    sendAsync(request, signal = null) {
        return fetch(request, { signal });
    }

    /**
     * Fetch JSON data from an API endpoint.
     *
     * @param {URL} url - a url
     * @param {AbortSignal} [signal] - an abort signal
     *
     * @returns {Promise<Object>}
     */
    async getFromJsonAsync(url, signal = null) {
        let headers = new Headers();
        headers.append("Accept", "application/json");
        let request = new Request(url, { headers });
        let response = await this.sendAsync(request, signal);
        if (!response.ok) {
            throw await ServiceException.CreateAsync(response);
        }
        return await response.json();
    }

    /**
     * A JSON API request method, where failures are transformed into ServiceExceptions
     *
     * @param {URL} url - a url
     * @param {AbortSignal} [signal] - an abort signal
     * @param {boolean} [lazy=false] - a flag indicating the next page should be lazy-loaded
     *
     * @returns {Promise<ResultSet>}
     */
    async scrollFromJsonAsync(url, signal = null, lazy = false) {
        let page = await this.getFromJsonAsync(url, signal);
        return ResultSet.Create(this, page, signal, lazy);
    }

    /**
     * An attachment API request
     *
     * @param {URL} url - a url
     * @param {AbortSignal} [signal] - an abort signal
     *
     * @returns {Promise<Blob>}
     */
    async getAttachmentAsync(url, signal = null) {
        let request = new Request(url);
        let response = await this.sendAsync(request, signal);
        if (!response.ok) {
            throw await ServiceException.CreateAsync(response);
        }
        return await response.blob();
    }

}

/**
 * A unidirectional scrolling result set that scrolls directly through pages, performing requests as required.
 */
class ResultSet {

    #handler;

    #rows;

    #next;

    #promise;

    constructor(handler, rows, next, promise) {
        this.#handler = handler;
        this.#rows = rows;
        this.#next = next;
        this.#promise = promise;
    }

    /**
     * True iff more results might be available.
     * @type {boolean}
     */
    get hasNext() {
        return !!this.#next;
    }

    /**
     * A pagination token for the next page in the result set.
     * @type {string?}
     */
    get cursor() {
        if (this.#next) {
            let q = new URL(this.#next).search ?? null;
            return q && q.startsWith("?") ? q.substring(1) : q
        } else {
            return null;
        }
    }

    /**
     * All rows in the local page.
     * @type {Array}
     */
    get rows() {
        return this.#rows;
    }

    /**
     * Load the next page, and notify the caller if there are more rows to be loaded.
     *
     * @param {AbortSignal} [signal] - an abort signal
     *
     * @returns {Promise<boolean>}
     */
    async loadMoreAsync(signal = null) {
        let current = this.#promise;
        if (current)
        {
            let page = await current;
            if (page) {
                this.#rows = page.rows ?? [];
                this.#next = page['@next'] ?? null;
                this.#promise = this.#next ? this.#handler.getFromJsonAsync(this.#next, signal) : null;
                return true;
            } else {
                this.#rows = [];
                this.#next = null;
                this.#promise = null;
                return false;
            }
        } else if (this.#next) {
            let page = await this.#handler.getFromJsonAsync(this.#next, signal);
            if (page) {
                this.#rows = page.rows ?? [];
                this.#next = page['@next'] ?? null;
                return true;
            } else {
                this.#rows = [];
                this.#next = null;
                return false;
            }
        } else {
            return false;
        }
    }

    /**
     * A transformation method for result set entries.
     *
     * @params {function} fn - a mapping function for each element
     *
     * @returns {ResultSet} A new result set containing the mapped elements
     */
    map(fn) {
        return new MappingResultSet(this, fn);
    }

    /**
     * A utility method for constructing result sets
     * @param {HttpHandler} handler - an http handler
     * @param {AbortSignal} [signal] - an abort signal
     * @param {boolean} [lazy=false] - a flag indicating the next page should be lazy-loaded
     *
     * @returns {ResultSet}
     */
    static Create(handler, page, signal = null, lazy = false) {
        if (page) {
            let promise = (lazy || !page['@next']) ? null : handler.getFromJsonAsync(page['@next'], signal);
            return new ResultSet(handler, page.rows ?? [], page['@next'] ?? null, promise);
        } else {
            return new ResultSet(handler, [], null, null);
        }

    }

}

/**
 * A derived result set that consumes a source result set and maps each item.
 */
class MappingResultSet {

    #src;

    #fn;

    #rows;

    constructor(src, fn) {
        this.#src = src;
        this.#fn = fn;
        this.#rows = [];
    }

    /**
     * True iff more results might be available.
     * @type {boolean}
     */
    get hasNext() {
        return this.#src.hasNext;
    }

    /**
     * A pagination token for the next page in the result set.
     * @type {string?}
     */
    get cursor() {
        return this.#src.cursor;
    }

    /**
     * All rows in the local page.
     * @type {Array}
     */
    get rows() {
        if (this.#rows.length != this.#src.rows.length) {
            this.#rows = this.#src.rows.map(this.#fn);
        }
        return this.#rows;
    }

    /**
     * Load the next page, and notify the caller if there are more rows to be loaded.
     *
     * @param {AbortSignal} [signal] - an abort signal
     *
     * @returns {Promise<boolean>}
     */
    loadMoreAsync(signal = null) {
        this.#rows = [];
        return this.#src.loadMoreAsync(signal);
    }

    /**
     * A transformation method for result set entries.
     *
     * @params {function} fn - a mapping function for each element
     *
     * @returns {ResultSet} A new result set containing the mapped elements
     */
    map(fn) {
        return new MappingResultSet(this, fn);
    }

}

/**
 * An exception type used to pass service errors up the stack.
 */
export class ServiceException extends Error
{

    /**
     * The error constructor, intended only for internal use.
     *
     * @param {int} status - The HTTP status code for the request
     * @param {Object} error - The underlying error
     * @param {Error} cause - The underlying cause, if the error was a local runtime exception
     */
    constructor(status, error, cause = null) {
        super(error.title, cause);
        this.status = status;
        this.error = error;
    }

    /**
     * A utility method for constructing service exceptions
     */
    static async CreateAsync(response)
    {
        let text = await response.text();
        let error = null;
        let cause = null;
        try {
            error = JSON.parse(text);
        } catch (e) {
            cause = e;
        }

        return new ServiceException(response.status, error ?? {
            code: "evaluation.3000",
            title: "An unexpected response was received from the server",
            detail: text,
            errors: [],
        }, cause);
    }

}