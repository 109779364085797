<template>
  <footer class="uk-section uk-section-primary uk-light uk-width-viewport uk-padding-small">
      <div class="uk-container uk-text-center">
          <div class="uk-flex uk-flex-center uk-grid-divider uk-child-width-1-3 uk-grid-small" uk-grid>
              <a href="/terms-of-use" class="uk-inline uk-link-muted">
                Terms of use
              </a>
              <a href="/privacy" class="uk-inline uk-link-muted">
                Privacy policy
              </a>
              <p class="uk-text-muted uk-inline">© 2022 Squirrel Technologies Ltd.</p>
          </div>
      </div>
  </footer>
</template>

<script>
export default {
  name: 'SiteFooter',
};
</script>