<template>
  <header>
    <h1 class="uk-text-center"><a class="uk-link-heading" href="/">acorn</a></h1>
  </header>
</template>

<script>
export default {
  name: 'SiteHeader',
};
</script>