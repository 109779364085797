<template>
  <Lock helpless>
    <article class="uk-height-viewport uk-flex uk-flex-column uk-flex-middle">
      <div class="uk-flex-1"></div>
      <section class="uk-width-large  uk-padding uk-flex-center uk-flex uk-flex-column uk-text-center">
        <SiteHeader></SiteHeader>
        <h2 class="">{{ $label('error.missing') }}</h2>
        <p class="uk-margin-left uk-margin-right uk-text-center">
          {{ $message('error.missing') }}
        </p>
        <div class="uk-margin">
          <router-link to="/home">{{ $message('nav.home') }}</router-link>
        </div>
      </section>
      <div class="uk-flex-1"></div>
      <SiteFooter class="uk-flex-none uk-flex-bottom"></SiteFooter>
    </article>
  </Lock>
</template>

<script>
import Lock from '@/components/Lock.vue';
import SiteHeader from '@/components/SiteHeader.vue';
import SiteFooter from '@/components/SiteFooter.vue';

export default {
  name: 'error-404',
  components: {
    Lock,
    SiteHeader,
    SiteFooter,
  },
};
</script>
