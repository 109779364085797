import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import Error404 from './views/Error404.vue';

const OAuth = () => import('./views/OAuth.vue')
const Canvas = () => import('./views/Canvas.vue')
const Projects = () => import('./views/Projects.vue')
const Project = () => import('./views/Project.vue')
const Code = () => import('./views/Code.vue')
const ReleaseTool = () => import('./views/ReleaseTool.vue')
const Privacy = () => import('./views/Privacy.vue')
const TermsOfUse = () => import('./views/TermsOfUse.vue')
const TermsOfService = () => import('./views/TermsOfService.vue')

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      alias: '/',
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        title: () => "acorn | Squirrel Technologies",
      }
    },
    {
      path: '/project/:wiki',
      name: 'projects',
      component: Projects,
      props: true,
      meta: {
        title: params => `${params.wiki} | acorn`,
      }
    },
    {
      path: '/oauth/:wiki',
      component: OAuth,
      props: (route) => ({
        wiki: route.params.wiki,
        token: route.query.oauth_token,
        state: route.query.state,
        verifier: route.query.oauth_verifier,
      }),
      meta: {
        title: params => `${params.wiki} | acorn`,
      }
    },
    {
      path: '/canvas/:wiki/:path',
      alias: '/design/:wiki/:path',
      name: 'canvas',
      component: Canvas,
      props: (route) => ({
        wiki: route.params.wiki,
        path: route.params.path,
        label: route.query.label,
        version: route.query.version,
      }),
      meta: {
        title: params => `edit ${params.path} | ${params.wiki}`,
      }
    },
    {
      path: '/project/:wiki/:path',
      name: 'project',
      component: Project,
      props: true,
      meta: {
        title: params => `manage ${params.path} | ${params.wiki}`,
      }
    },
    {
      path: '/project/:wiki/:path/release/:label',
      name: 'release',
      component: ReleaseTool,
      props: true,
      meta: {
        title: params => `${params.label} ${params.path} | ${params.wiki}`,
      }
    },
    {
      path: '/project/:wiki/:path/code/:label',
      name: 'code',
      component: Code,
      props: true,
      meta: {
        title: params => `create ${params.path} | ${params.wiki}`,
      }
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: Privacy,
      meta: {
        title: () => "Privacy | acorn",
      }
    },
    {
      path: '/terms-of-use',
      name: 'termsofuse',
      component: TermsOfUse,
      meta: {
        title: () => "Terms of Use | acorn",
      }
    },
    {
      path: '/terms-of-service',
      name: 'termsofservice',
      component: TermsOfService,
      meta: {
        title: () => "Terms of Service | acorn",
      }
    },
    {
      path: '*',
      name: 'missing',
      component: Error404,
      meta: {
        title: () => "Not Found | acorn",
      }
    },
  ],
});

router.afterEach((to) => {
  document.title = to.meta.title ? to.meta.title(to.params) : "acorn | Squirrel Technologies";
});

export default router;